.landing-header-image {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('assets/img/codeThroughGlasses.jpeg');
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.8;
}

.description-dark {
  color: #353535;
}
